@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef2f8;
  color: black;
  scrollbar-color: #A8A8A8;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
}

.bg-body {
  background-color: rgb(34 34 34);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  height: calc(100vh - 74px);

  margin-top: 74px;
}

.athlete-list {
  max-height: calc(100vh - 250px);
}

.card {
  background-color: #FFFFFF;
  color: black;
}

.bg-mfx-blue {
  background: #1c1f2c;
}

.border-mfx-blue {
  border-color: #1c1f2c;
}

.hover-mfx-blue:hover {
  background-color: #1c1f2c;
  color: white;
}

.bg-mfx-lightblue {
  background: #eef2f8;
}

.primary-btn {
  background: #1c1f2c;
  color: white;
  border: none;
  cursor: pointer;
}

.outline-btn {
  background: none;
  color: #1c1f2c;
  border: 2px solid #1c1f2c;
  cursor: pointer;
}

.outline-btn:hover {
  background: #1c1f2c;
  color: white;
}

.table-auto {
  td {
    padding: 0.75rem;
  }
  
  thead {
    tr {
      background: none!important;
      border-bottom: 2px solid #1c1f2c;
      th {
        padding-bottom: 0.75rem;
        font-size: small;
      }
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #1c1f2c;
  }
}

.table-link-row {
  cursor: pointer;
}

.table-link-row:hover {
  td {
    h3 {
      text-decoration: underline;
    }
  }
}

.tr-no-color {
  background: none!important;
}

.primary_input {
  background: none;
  border: 1px solid #A0A0A0;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.force-no-rounded {
  border-radius: 0!important;
}

.scrollbar-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .card {
    max-height: 80vh;
  }
}

.calendar-marker {
  height: 5px;
  width: 5px;
  background-color: #FFFFFF; /* Color for the marker */
  border-radius: 50%;
  display: inline-block;
}

.react-calendar {
  background: transparent!important;
  border: none!important;
  font-weight: 600;
}

.react-calendar__tile--now {
  background: #555555!important;
  border-radius: 5px;
}

.react-calendar__tile--active {
  background: rgba(83, 204, 26, 0.25)!important;
  color: rgba(83, 204, 26, 1)!important;
  border-radius: 15px;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit!important;
}

abbr:where([title]) {
  -webkit-text-decoration: none!important;
  text-decoration: none!important;
}

/* Styles that apply only below md: breakpoint */
@media (max-width: 767px) {
  .hidden-below-md {
    display: none!important ;
  }
}

/* Styles that apply only above md: breakpoint */
@media (min-width: 768px) {
  .sidenav-offset {
    margin-left: 128px
  }
  .sidenav-offset-extended {
    margin-left: 244px
  }
  .hide-above-md {
    display: none;
  }
}

.toggle-switch {
  width: 30px;
  height: 17px;
  background-color: #ccc;
  border-radius: 17px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-switch.on {
  background-color: #4cd964;
}

.toggle-knob {
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.2s;
}

.toggle-switch.on .toggle-knob {
  left: 15px;
}